export const ssocd = {
  name: "SocialdemokratSocD",
  img: "/assets/ssocd.png",
  answers: [
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
  ],
};
