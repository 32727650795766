export const kd = {
  name: "Kristdemokraterterna",
  img: "/assets/kd.png",
  answers: [
    {
      answer: "JA",
      description: `Under de senaste två åren har Sverige minskat sin diplomatiska närvaro på Taiwan. Sverige är nu det enda europeiska land vars representant inte är permanent bosatt i Taipei. Regeringen bär ansvaret för att det har blivit så. Arbetet med att återgå till en permanent ordning måste ske skyndsamt.`,
    },
    {
      answer: "JA",
      description: `Vi noterar den kritik som har framförts mot den svenska representanten. Självklart måste svenska utsända visa sin närvaro och aktivt delta i den typen av evenemang.`,
    },
    {
      answer: "JA",
      description: `Kristdemokraterna står bakom riksdagens tillkännagivande om att inrätta ett House of Sweden i Taipei. Därtill vill vi att Sverige ansluter sig till skaran av länder som utan mellanhänder erbjuder omfattande konsulär service på plats i Taiwan.`,
    },
    {
      answer: "JA",
      description: `Om Taiwan önskar byta namn på sin representation i Sverige, så menar vi att regeringen borde ge sitt medgivande till det. Vi välkomnar att Taiwan öppnat ett representationskontor i Vilnius och fördömer de sanktioner som Kina infört mot Litauen till följd av det namn som kontoret getts.`,
    },
    {
      answer: "JA",
      description: `Sverige bör ta initiativ till förhandlingar mellan EU och Taiwan om ett bilateralt investeringsavtal (BIA) gällande investeringar, offentlig upphandling och marknadstillträde för handel med tjänster, och i förlängningen också ett heltäckande ekonomiskt avtal (ECA). Vi behöver underlätta för export av europeiska varor till Taiwan och locka fler taiwanesiska företag att investera i Europa. Därtill välkomnar Kristdemokraterna initiativ som stärker de kulturella banden mellan våra länder. Före pandemin tecknades flera samarbetsavtal mellan svenska och taiwanesiska universitet och nu är det viktigt att dessa utbyten kommer igång.`,
    },
    {
      answer: "JA",
      description: `Vi ska inte låta Kina diktera villkoren för de svenska förbindelserna med Taiwan.`,
    },
    {
      answer: "JA",
      description: `Kristdemokraterna anser att export av svensk försvarsmateriel ska vara förbehållet demokratier och att säkerhetspolitiska hänsyn skall ligga till grund för beslut om all export. Det ligger i Sveriges intressen att Taiwan förblir ett fritt, självständigt och demokratiskt land. Bland de främsta exportörerna av försvarsmateriel till Taiwan finns EU-länderna Frankrike och Nederländerna. Mot bakgrund av den ökade hotbilden mot Taiwan anser Kristdemokraterna att även Sverige bör överväga att exportera materiel till Taiwan som är avsett för självförsvar mot en potentiell angripare.`,
    },
    {
      answer: "JA",
      description: `Taiwan har hanterat covid-19-pandemin på ett föredömligt sätt. WHO:s styvmoderliga behandling av landet måste fördömas.`,
    },
    {
      answer: "JA",
      description: `Vi tycker att det vore bra meden direkt flygförbindelse mellan Sverige och Taiwan. Det är dock inte svenska staten som beslutar om vilka flyglinjer som etableras, det är upp till flygbolagen. Det behöver även säkerställas att det inte finns lagliga hinder som förhindrar enskilda flygbolag att ansöka om trafiktillstånd på en sådan rutt.`,
    },
    {
      answer: "NEUTRAL",
      description: `För oss är den viktigaste prioriteringen att taiwanesiska representanter ges möjlighet till meningsfullt deltagande på den internationella scenen. Detta sker idag under namn som ”Chinese Taipei” et al.`,
    },
    {
      answer: "NEJ",
      description: `Vi kommer aldrig medverka till att Taiwan styrs av det kinesiska kommunistpartiet, vare sig direkt eller indirekt. Kinas alltmer aggressiva beteende och Taiwans ökade utsatthet förtjänar större uppmärksamhet från svenskt håll.`,
    },
    {
      answer: "JA",
      description: `Det behöver säkerställas att svenska myndigheter inte ger efter för kinesiska påtryckningar. Skatteverkets beslut medför en ökad risk för att svensk myndighetspost avsedd för mottagare i Taiwan av misstag skickas till det kinesiska fastlandet.`,
    },
    {
      answer: "JA",
      description: `Vi beklagar att regeringen dragit ner på den svenska närvaron i Taiwan, vilket vi menar går stick i stäv med de höga ambitionerna som finns i demokratioffensiven. Taiwan är ett demokratiskt föredöme och en självklar partner i detta arbete. Socialdemokraterna måste göra upp sin dubbelmoral i hur man behandlar Taiwan.`,
    },
    {
      answer: "JA",
      description: `Vi välkomnar initiativ som stärker de kulturella, ekonomiska och politiska banden mellan Sverige och Taiwan.`,
    },
    {
      answer: "JA",
      description: `Kristdemokraterna står upp för en värderingsbaserad utrikespolitik som främjar frihet, demokrati och mänskliga rättigheter. Vi ser Taiwan som en självklar partner i detta arbete och ett föredöme för såväl Sverige som för andra länder.`,
    },
  ],
}