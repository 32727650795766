export const cc = {
  name: "Centerpartiet",
  img: "/assets/c.png",
  answers: [
    {
      answer: "JA",
      description: `Det är vår hållning och en del i uppgradering av vår representation till ett House of Sweden`,
    },
    {
      answer: "JA",
      description: `Vi vill se ett vidgat uppdrag och det är en del av detta.`,
    },
    {
      answer: "NEUTRAL",
      description: `Här har vi inte tagit ställning men ser att vi bör samordna oss med likasinnade`,
    },
    {
      answer: "NEUTRAL",
      description: `Det är inte slutligt avdömt ännu. Även här bör vi söka samordnat agerande från likasinnade stater`,
    },
    {
      answer: "JA",
      description: `Det är en del av det vidgade uppdraget för vår närvaro på Taiwan.`,
    },
    {
      answer: "JA",
      description: `Vi vill ju se en starkare närvaro även politiskt och därmed även fördjupa samarbetet även till andra områden än handel.`,
    },
    {
      answer: "NEJ",
      description: `Idag är det inte förenligt med svensk Krigsmateriellagstiftning då export sker till stater.`,
    },
    {
      answer: "JA",
      description: `Vi har drivit på och kommer fortsätta med det för att Taiwan ska kunna bidra till lösningar på våra gemensamma utmaningar.`,
    },
    {
      answer: "JA",
      description: `Det är rimligt att den möjligheten öppnas om någon vill driva en sådan linje för att konkurrera med t ex Turkish Airlines.`,
    },
    {
      answer: "NEUTRAL",
      description: `En fråga som ännu inte är avgjord för vår del. Kräver även här en mer strategisk diskussion med likasinnade före ställningstagande.`,
    },
    {
      answer: "NEUTRAL",
      description: `Vi står bakom, med vår tolkning, av Ett land, två system. Men CCP:s hantering av Hongkong reser många frågor som ännu inte har ett färdigt svar. Självklart måste det vara det taiwansiska folkets genuina vilja om så ska ske. Ett försökt till militärt övertagande måste tydligt och i tid mötas med klara konsekvenser.`,
    },
    {
      answer: "JA",
      description: `Det är rimligt.`,
    },
    {
      answer: "JA",
      description: `Ja, det är viktigt att vi stärker banden mellan demokratier och stöttar varandra.`,
    },
    {
      answer: "NEUTRAL",
      description: `Vi har ingen partiuppfattning om vilka samarbeten kommuner etablerar med undantag för att inte stödja brott mot MR eller diktaturer.`,
    },
    {
      answer: "JA",
      description: `Taiwan har utvecklats till en stark demokrati i Sydostasien och i demokratisatsningen är det viktigt att fånga en bredd av partners.`,
    },
  ],
}