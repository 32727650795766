export const ll = {
  name: "LiberalernasL",
  img: "/assets/ll.png",
  answers: [
    {
      answer: "JA",
      description: `Det är viktigt att Sverige är närvarande och tillgängligt i Taipei och i hela Taiwan. Ett sätt att uppnå detta skulle vara att vår/våra representanter i huvudsak baseras i Taipei.`,
    },
    {
      answer: "JA",
      description: `Från svensk sida bör vi som lägsta nivå eftersträva att vara lika aktiva som andra EU-länder. Sverige och Taiwan har dock mer gemensamt än Taiwan och de flesta andra EU-länder. Av det skälet vore det naturligt om Sverige snarare var ett av de mest aktiva och närvarande länderna i Taiwan. `,
    },
    {
      answer: "JA",
      description: `Liberalernas värderingsstyrda utrikespolitik gör det självklart för oss att verka för att detta ska bli möjligt för Sveriges representationskontor i Taiwan. I fallet Taiwan har liberalerna fattat ett tydligt beslut om att Taiwans folk fritt måste få bestämma sin egen framtid och att Liberalerna ska stötta Taiwan i detta. För att valet ska vara fritt måste det såklart ske utan hänsyn till Folkrepubliken Kinas eventuella reaktioner. Till detta kan läggas att Sverige har mycket att tjäna på en god och nära relation till Taiwan, något som det konsulära arbetet kan bidra till.`,
    },
    {
      answer: "JA",
      description: `Folkrepubliken Kina har lika lite med Sveriges och Taiwans relationer att göra som vilken annan utomstående part som helst. Detta betyder att vi anser att även denna fråga ska vara Taiwans sak att avgöra och att Sverige ska stödja det beslut som Taiwan fattar. `,
    },
    {
      answer: "JA",
      description: ``,
    },
    {
      answer: "JA",
      description: `Vi stödjer alla Taiwans initiativ till ökat politiskt samarbete med Sverige men också alla försök till deltagande i olika internationella organisationer eller samarbeten.`,
    },
    {
      answer: "JA",
      description: `aiwan är en av världens mest stabila demokratier och har rätt att försvara sig. Detta ligger också i linje med den utrikespolitik Liberalerna vill att regeringen driver. Till det bör såklart läggas att all svensk vapenexport ska följa svensk lagstiftning.`,
    },
    {
      answer: "JA",
      description: `Se svar på fråga 6.`,
    },
    {
      answer: "JA",
      description: `Vi är positiva till direktflyg mellan Stockholm och Taipei och menar att alla eventuella politiska hinder måste undanröjas men anser samtidigt inte att beslut kring flygrutter bör fattas politiskt. I en fri ekonomi måste flygbolagen själva fatta dessa beslut på affärsmässiga grunder.`,
    },
    {
      answer: "JA",
      description: `Vi anser att Taiwan själva bör få fatta beslut om sin beteckning i alla internationella sammanhang, men anser samtidigt inte att de svenska idrottsförbundens hållning ska beslutas politiskt. Det ska givetvis aldrig heller accepteras att Folkrepubliken Kina använder idrotten som ett utrikespolitiskt påtryckningsinstrument mot Taiwan och taiwanesiska idrottare.`,
    },
    {
      answer: "NEJ",
      description: `Det taiwanesiska folket har rätt att själva bestämma sin egen framtid. Helt fritt från påtryckningar eller hot från Folkrepubliken Kina. Till detta bör läggas att inte heller Folkrepubliken Kina längre verkar stödja modellen ”Ett land, två system”. De har med våld i praktiken avskaffat det relativt demokratiska system som fanns i Hongkong. Vi kan inte acceptera inskränkningar av mänskliga rättigheter i Hongkong, och vi kan inte acceptera att den kinesiska diktaturen inte låter vare sig sina egna medborgare eller Hongkongs medborgare att leva i frihet och demokrati.`,
    },
    {
      answer: "JA",
      description: `Det vore mer korrekt.`,
    },
    {
      answer: "JA",
      description: `Taiwan är en av världens mest stabila demokratier, att inte inom ramen för den så kallade demokratioffensiven visa solidaritet med Taiwan är bisarrt.`,
    },
    {
      answer: "JA",
      description: `Vi är alltid positiva till goda relationer mellan Sverige och Taiwan. Detta måste dock vara upp till kommunerna själva att avgöra.`,
    },
    {
      answer: "JA",
      description: `Taiwan är en välfungerande demokrati med respekt för mänskliga rättigheter och den regelbaserade världsordningen. Sverige och Taiwan delar värderingar och har en gemensam framtidsvision för en fri och öppen värld. Taiwan är precis som Sverige en självständig och stabil liberal demokrati.
      Samtidigt lever Taiwan lever i skuggan av världens allvarligaste antidemokratiska hot, Folkrepubliken Kina. I världspolitiken pågår just nu i både stort och smått en kraftmätning mellan demokrati och diktatur, mellan liberala och auktoritära statsskick. Ett uppenbart exempel på detta är hur diktaturen Kina ständigt utgör ett överhängande hot mot demokratin Taiwan. En del i Kinas allt mer aggressiva politik för att försvaga Taiwan och förvägra det taiwanesiska folket deras rättigheter är att med hot och bufflighet avhålla omvärlden från att stötta Taiwan.`,
    },
  ],
}