import React from "react";

export const Start = (props: any) => {
  const { setStage } = props;

  return (
    <div className="start-section">
      <h1>
        Valkompass
        <br /> Sverige-Taiwan
      </h1>
      <p>
        Som förening vill vi i möjligaste mån verka för stärkta band och ökad
        förståelse mellan befolkningen i Sverige och Taiwan
      </p>
      <button className="primary" onClick={() => setStage("questions")}>
        Låt oss börja
      </button>
    </div>
  );
};
