import React from "react";
import { Progress } from "antd";

export const Result = (props: any) => {
  const { setStage, restart, result } = props;

  return (
    <div className="result-section">
      <div className="result-blk">
        <div className="title-row">
          <h3>Dina resultat</h3>

          <button
            onClick={() => setStage("analysis")}
            className="primary small"
          >
            Analys
          </button>
        </div>

        <div className="result-data">
          {result &&
            result.map((item: any, i: any) => {
              const perc = item.percentage.toFixed(2);
              let color = "#0069A5";
              // Stroke Color
              if (perc > 70) color = "#3DC272";
              if (perc < 30) color = "#EA437A";

              return (
                <div className="result-row" key={i}>
                  <p>{item.name}</p>
                  <img src={item.img} alt={item.name} />
                  <Progress percent={perc} strokeColor={color} />
                </div>
              );
            })}
        </div>
      </div>

      <div className="bottom-section">
        <p>Socialdemokraterna besvarade inte alls enkäten, trots påminnelse.</p>
        <div className="restart" onClick={() => restart()}>
          <img src="/assets/refreshing.png" alt="restart-icon" />
          <p>Omstart</p>
        </div>
      </div>
    </div>
  );
};
