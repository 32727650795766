import React, { useMemo, useState } from "react";
import { Container, Footer } from "../../components";
import { questions, analysis } from "./data";
import { Question } from "./question";
import { Start } from "./start";
import { Result } from "./result";
import { Analysis } from "./analysis";

export const Home = (props: any) => {
  const [stage, setStage] = useState<string>("start");
  const [myAns, setMyAns] = useState<[]>([]);
  const [question, setQuestion] = useState<Number>(0);
  const [showDesc, setShowDesc] = useState<boolean>(false);

  const restart = () => {
    setQuestion(0);
    setMyAns([]);
    setStage("start");
  };

  const result = useMemo(() => {
    if (myAns.length === 0) return [];

    let data: any = [];
    let totalQuestion = questions.length;

    for (let obj of analysis) {
      let name = obj["name"];
      let img = obj["img"];
      let match = 0;
      for (let i in obj["answers"]) {
        if (obj["answers"][i]["answer"] !== myAns[i]) continue;
        match += 1;
      }

      data = [
        ...data,
        {
          name,
          img,
          match,
          percentage: (match / totalQuestion) * 100,
        },
      ];
    }

    data.sort((a: any, b: any) => b["percentage"] - a["percentage"]);

    return data;
  }, [myAns]);

  return (
    <Container>
      <div className="flex-row home-page">
        {stage === "start" && <Start setStage={setStage} />}
        {stage === "questions" && (
          <Question
            setStage={setStage}
            data={questions}
            question={question}
            setQuestion={setQuestion}
            myAns={myAns}
            setMyAns={setMyAns}
            restart={restart}
            setShowDesc={setShowDesc}
            showDesc={showDesc}
          />
        )}
        {stage === "result" && (
          <Result
            setStage={setStage}
            data={questions}
            restart={restart}
            result={result}
          />
        )}
        {stage === "analysis" && (
          <Analysis
            setStage={setStage}
            questions={questions}
            restart={restart}
            result={result}
            myAns={myAns}
            parties={analysis}
          />
        )}
      </div>

      <Footer data={analysis} />
    </Container>
  );
};
