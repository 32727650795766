import React from "react";

export const Footer = (props: any) => {
  let { data } = props;

  return (
    <div className="footer">
      <div className="icon-list">
        {data.map((item: any, i: any) => {
          return <img src={item.img} alt={item.name} key={i} />;
        })}
      </div>

      <div className="represent">
        <div className="icon-list">
          <img src={"/assets/swetailogo.png"} alt={"logo"} />
          <img
            src={"/assets/logotaSwedenheart.png"}
            alt={"logotaSwedenheart"}
          />
        </div>
        <p>
          <i>By SweTai & TASweden</i>
        </p>
      </div>
    </div>
  );
};
