export const ssd = {
  name: "SverigedemokraterSD",
  img: "/assets/ssd.png",
  answers: [
    {
      answer: "JA",
      description: `Självklart bör Sveriges representant vara baserad i Taipei. Vidare måste den svenska representationen i Taiwan utvecklas ytterligare och förbättras avsevärt.`,
    },
    {
      answer: "JA",
      description: `Självklart. Vi ska vara så aktiva som möjligt.`,
    },
    {
      answer: "JA",
      description: `Inget land ska bestämma över Sveriges konsulära angelägenheter som exempelvis konsulär status.`,
    },
    {
      answer: "JA",
      description: `Ja, det vore både enklare och korrekt utifrån representationskontorets de facto uppdrag.`,
    },
    {
      answer: "JA",
      description: `Vi har stora skäl att utveckla detta samarbete, då inte minst det ekonomiska samarbetet.`,
    },
    {
      answer: "JA",
      description: `Taiwan och Sverige delar en lång rad fundamentala värden och det finns mycket att tjäna på utökat samarbete.`,
    },
    {
      answer: "JA",
      description: `Givetvis ska våra vänner i Taiwan få vårt stöd. Det handlar om en av världens mest utvecklade demokratier, med en välfungerande rättsstat och en god syn på mänskliga rättigheter.`,
    },
    {
      answer: "JA",
      description: `Detta är en av flera frågor som vi återkommande har lyft som mycket viktiga.`,
    },
    {
      answer: "JA",
      description: `Det skulle gynna såväl Sverige som Taiwan.`,
    },
    {
      answer: "JA",
      description: `De representerar Taiwan. Då ska där inte stå något annat än att de representerar Taiwan.`,
    },
    {
      answer: "NEJ",
      description: ``,
    },
    {
      answer: "JA",
      description: `Det finns inget försvar till att myndigheterna ändrat till "provins i Kina".`,
    },
    {
      answer: "JA",
      description: `Det borde vara en självklarhet att en av världens främsta demokratier ska vara djupt prioriterad i det som kallas för den svenska demokratioffensiven.`,
    },
    {
      answer: "JA",
      description: `Det finns mycket goda skäl för svenska samhällen att utöka partnerskap med lyckade städer. Då är Taiwan ett perfekt ställe.`,
    },
    {
      answer: "JA",
      description: `Det borde inte finnas någon tvekan om detta.`,
    },
  ],
}