import React from "react";

const answers = ["JA", "NEUTRAL", "NEJ"];

export const Question = (props: any) => {
  const {
    setStage,
    data,
    question,
    setQuestion,
    myAns,
    setMyAns,
    restart,
    showDesc,
    setShowDesc,
  } = props;

  const answer = (ans: String) => {
    const totalQuestion = data.length;
    if (question < totalQuestion - 1) {
      setQuestion(question + 1);
    }

    setMyAns([...myAns, ans]);

    if (question >= totalQuestion - 1) {
      setStage("result");
    }
  };

  return (
    <div className="question-section">
      <h2>
        {question + 1}/ {data[question].question}
      </h2>

      <div className="desc-blk">
        <h3 onClick={() => setShowDesc(!showDesc)}>
          Visa beskrivning {showDesc ? "-" : "+"}
        </h3>

        {showDesc && <div className="shadow">{data[question].desc}</div>}
      </div>

      <div className="bottom-section">
        <div className="answer-btns">
          {answers.map((item, i) => {
            return (
              <button
                className={`${item.toLowerCase()}`}
                key={i}
                onClick={() => answer(item)}
              >
                {item}
              </button>
            );
          })}
        </div>

        <div className="restart" onClick={() => restart()}>
          <img src="/assets/refreshing.png" alt="restart-icon" />
          <p>Omstart</p>
        </div>
      </div>
    </div>
  );
};
