export const mm = {
  name: "ModeraterM",
  img: "/assets/mm.png",
  answers: [
    {
      answer: "JA",
      description: `M vill upprätta närvaro i form av ett House of Sweden och har drivit det i riksdagen. Särskilt i kontrast till S.`,
    },
    {
      answer: "JA",
      description: `Vi vill se ett aktivt House of Sweden och att uppgifter som i dag ligger på ambassaden i Bangkoks ansvarsområden flyttas över.`,
    },
    {
      answer: "NEUTRAL",
      description: `Formerna för närvaro mellan Sverige och Taiwan kan komma att behöva att ses över. Men något löfte kan inte avges.`,
    },
    {
      answer: "NEUTRAL",
      description: `Sverige står i likhet med huvudsakligen alla västliga länder bakom ”Ett Kina”-politiken men inom ramen för denna finna fungerande utbyte och relationer med Taiwan. Det viktigaste är att ha ett rikligt utbyte på alla nivåer. Det kommer M att verka för i regeringsställning.`,
    },
    {
      answer: "JA",
      description: ``,
    },
    {
      answer: "JA",
      description: `Sverige avgör själv hur omvärldsrelationer ska utformas.`,
    },
    {
      answer: "NEUTRAL",
      description: `Det finns ett regelverk för svensk krigsmaterielexport. Jag vet inte om det för närvarande finns något intresse att köpa och från något svenskt företag att sälja. Mot Folkrepubliken Kina råder ett absolut försäljningsförbud sedan regimen slog ned de oppositionella krafterna vid Himmelska Fridens torg.`,
    },
    {
      answer: "JA",
      description: `M har drivit på mot nuvarande regering i dessa frågor.`,
    },
    {
      answer: "JA",
      description: `Om något flygsällskap vill upprätta direkt flyglinje vore det mycket uppskattat.`,
    },
    {
      answer: "NEUTRAL",
      description: `Har ingen uppfattning om detta då frågan avgörs av oberoende idrottsorganisationer.`,
    },
    {
      answer: "NEJ",
      description: `Taiwan kan inte med tvång eller våld tvingas in i Folkrepubliken. Utvecklingen i Hong Kong är mycket dyster och skrämmande. Så även Folkrepublikens farliga agerande i samband med talmannen Pelosis besök i Taiwan.`,
    },
    {
      answer: "JA",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: `Demokratier behöver inte direkt lära sig bli demokratier av svenska UD.`,
    },
    {
      answer: "JA",
      description: `Om så önskas. Och avsluta med sådana med Folkrepubliken Kina.`,
    },
    {
      answer: "JA",
      description: ``,
    },
  ],
}