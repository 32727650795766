export const mp = {
  name: "MijölpartietMP",
  img: "/assets/mp.png",
  answers: [
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "NEJ",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
    {
      answer: "JA",
      description: `För Miljöpartiet är det en självklar del av vår utrikespolitik att stå upp för Taiwans
självbestämmanderätt som en självständig och suverän stat. Miljöpartiet har inte
ståndpunkter i de detaljfrågor som avses i enkäten, men vill gärna ge ett generellt svar på
vår inställning till Taiwan och Sveriges diplomatiska relationer med Taiwan.

Vi beslutade på vår senaste kongress att Sverige tillsammans med övriga EU-länder ska
erkänna Taiwan som detta och stödja landet i dess strävan efter erkännande. Miljöpartiet
la även en motion i samband med European Green Party Council i december för att EU
och medlemsstaterna ska arbeta för närmare relationer mellan Taiwan och EU och att
mer aktivt agera för Taiwans självständighet.

Sverige och EU har här en viktig roll att spela. När stormakter som Kina trappar upp sitt
auktoritära agerande behöver vi visa stöd för de demokratiska krafterna som utsätts för
hot, trakasserier och inskränkningar.`,
    },
  ],
};
