export const vv = {
  name: "VänsterV",
  img: "/assets/vv.png",
  answers: [
    {
      answer: "JA",
      description: `Det bör vara utgångspunkten.`,
    },
    {
      answer: "JA",
      description: `Vi ser ingen anledning till att Sverige skulle agera annorlunda än andra EU-länder när det kommer till den sortens utbyten.`,
    },
    {
      answer: "NEUTRAL",
      description: `Vi menar att en sådan utveckling vore önskvärd. Det är dock viktigt att agera med viss försiktighet så att Sveriges agerande inte slår tillbaka mot Taiwans befolkning.`,
    },
    {
      answer: "JA",
      description: ``,
    },
    {
      answer: "JA",
      description: `Vi ser positivt på ett kulturellt och ekonomiskt samarbete med Taiwan.`,
    },
    {
      answer: "JA",
      description: `Vi menar att Sverige bör gå i en sådan riktning. Det är dock avgörande att det görs långsiktigt och med viss försiktighet. Sveriges agerande ska inte slå tillbaka på Taiwans befolkning.`,
    },
    {
      answer: "NEJ",
      description: `Sverige ska inte bidra till ökade spänningar och en militär upprustning i regionen. Vi bör istället verka för diplomati och avspänning.`,
    },
    {
      answer: "JA",
      description: `Det vore värdefullt med ett ökat internationellt deltagande i internationella sammanhang från Taiwans sida. Då krävs stöd från andra länder.`,
    },
    {
      answer: "JA",
      description: `Vi ser gärna ett ökat samarbete mellan Taiwan och de nordiska länderna Direktflyg är en konkret åtgärd. Transportstyrelsen borde göra mer för att underlätta att en sådan linje kommer till stånd.`,
    },
    {
      answer: "JA",
      description: `Vi menar att det ska vara möjligt att tävla under namnet Taiwan.`,
    },
    {
      answer: "NEJ",
      description: `Vi menar att det taiwanesiska folket själva ska få bestämma över sin framtid.`,
    },
    {
      answer: "JA",
      description: ``,
    },
    {
      answer: "NEUTRAL",
      description: ``,
    },
    {
      answer: "JA",
      description: `Partnerstadsrelationen kan vara ett bra verktyg för att skapa mellanfolkliga relationer.`,
    },
    {
      answer: "JA",
      description: `Taiwan är en skör och ny demokrati. Vi menar att det inte är så enkelt som att dela in världen i två kategorier. Utvecklingen i många länder är komplex och även liberala demokratier kan dras med stora problem. Med det sagt bör Sverige stödja den demokratiska utvecklingen i Taiwan.`,
    },
  ],
};
