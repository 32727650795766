import React from "react";
import { cc } from "./parties-data/cc";
import { kd } from "./parties-data/kd";
import { ll } from "./parties-data/ll";
import { mm } from "./parties-data/mm";
import { mp } from "./parties-data/mp";
import { ssd } from "./parties-data/ssd";
import { ssocd } from "./parties-data/ssocd";
import { vv } from "./parties-data/vv";

export const questions = [
  {
    question: `Anser du att den svenska representanten i Taiwan borde vara baserad i Taipei och huvudsakligen bosatt där under sitt uppdrag?`,
    desc: (
      <p>
        Sveriges nuvarande representant i Taiwan, Bengt Carlsson, bor, till
        skillnad från sin högt engagerade föregångare Håkan Jevrell, delvis i
        Stockholm, och tillbringar mer tid i Sverige än i Taiwan. I detta är han
        i stort sett ensam bland EU-representanter. Vid inerpellationsdebatt i
        riksdagen 25 mars 2022 motiverade Sveriges utrikesmininster Ann Linde
        detta förhållande genom att hänvisa till pandemin, trots att pandemin
        snarare torde försvåra just resande mellan Sverige och Taiwan, inte att
        vara bosatt i Taiwan under längre tid. Det bör även noteras att pandemin
        inte hindrat andra EU-länder från att ha representanter bosatta i
        Taiwan. (läs mer om debatten i regeringen Feb-Mars 2022 här:
        <br />
        <a
          href="https://www.riksdagen.se/sv/dokument-lagar/dokument/interpellation/starkta-diplomatiska-relationer-med-taiwan_H910340"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.riksdagen.se/sv/dokument-lagar/dokument/interpellation/starkta-diplomatiska-relationer-med-taiwan_H910340
        </a>
        <br />
        <a
          href="https://www.riksdagen.se/sv/dokument-lagar/dokument/skriftlig-fraga/kostnad-for-svensk-utrikesrepresentation_H9111811"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.riksdagen.se/sv/dokument-lagar/dokument/skriftlig-fraga/kostnad-for-svensk-utrikesrepresentation_H9111811
        </a>
        )
      </p>
    ),
  },
  {
    question: `Anser du att den svenska representanten i Taiwan borde aktivt delta i kulturella, ekonomiska och politiska aktiviteter i Taipei på samma sätt som andra EU-representanter?`,
    desc: (
      <p>
        Sveriges representant i Taiwan, Bengt Carlsson, visar sig mycket mer
        sällan på olika möten i Taiwan än sin föregångare, Håkan Jevrell, och
        även mycket mer sällan än representanter för andra EU-länder. Hans
        upprepade frånvaro från olika möten har väckt uppmärksamhet i
        diplomatkretsar i Taiwan.
      </p>
    ),
  },
  {
    question: `Anser du att Sveriges representationskontor i Taiwan borde få full konsulär status (med rätt att utfärda visum, pass osv.), även om detta skulle medföra försämrade relationer med Folkrepubliken Kina?`,
    desc: (
      <p>
        I nuläget kan man som svensk medborgare inte få ett nytt pass utfärdat
        av det svenska representationskontoret i Taipei, Business Sweden, utan
        man hänvisas till ambassader eller konsulat i andra närliggande länder
        som Thailand eller Japan. Även visumärenden hänvisas till dessa
        ambassader och konsulat. För svenskar bosatta i Taiwan eller för
        taiwaneser som vill ansöka om visum för lägre vistelser i Sverige är
        detta onödigt omständigt.{" "}
      </p>
    ),
  },
  {
    question: `Anser du att Taiwans representationskontor i Stockholm (som nu heter Taipei Mission in Sweden) borde få döpas om till "Taiwan in Sweden", även om detta skulle medföra försämrade relationer med Folkrepubliken Kina?`,
    desc: (
      <p>
        Taiwans de facto-ambassader har ofta en beteckning som inkluderar
        huvudstaden Taipeis namn, snarare än Taiwans namn. Detta beror på
        påtryckningar som Kina utövar på värdländerna, då kinesiska regimen vill
        undvika att dessa kontor ska uppfattas som representation för ett land.
        Samtidigt är det viktigt för Taiwans synlighet att det blir tydligt att
        dessa kontor representerar hela Taiwan, inte bara staden Taipei. "Taiwan
        in Sweden" och liknande namn är beteckningar som taiwanesiska
        representanter ofta önskar att använda i stället, men detta förhindras
        ofta av att värdlandet vill undvika att stöta sig med stormakten Kina.
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde fördjupa sitt kulturella och ekonomiska samarbete med Taiwan?`,
    desc: (
      <p>
        Taiwan och Sverige är på många sätt ganska lika, med en kombination av
        progressiv samhällsstruktur och högteknologisk ekonomisk struktur. Både
        Sverige och Taiwan skulle ha mycket att vinna på att samarbeta mer på
        det ekonomiska planet, och kulturella kontakter skulle göra det lättare
        och smidigare för svenskar och taiwaneser att komma närmare varandra.
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde inleda och fördjupa politiskt samarbete med Taiwan, även om detta skulle medföra försämrade relationer med Folkrepubliken Kina?`,
    desc: (
      <p>
        Även politiskt sett finns det stora likheter mellan Taiwan och Sverige.
        Sverige räknas (eller har räknats) som ett föregångsland i Europa när
        det gäller jämställdhet och social rättvisa, och erkännande av sexuella
        minoriteters rättigheter. Taiwan har sedan demokratiseringen på
        1990-talet utvecklat ett välfärdssamhälle som är föregångare bland
        östasiatiska länder, och Taiwan var också det första landet i Asien som
        erkände samkönade äktenskap. Taiwan har också utvecklat ett i det
        närmaste unikt system med digital demokrati, där medborgarinitiativ
        enkelt kan föra upp ärenden i politikens agenda. Politiskt sett skulle
        både Sverige och Taiwan tjäna på att utbyta erfarenhet, från lokal nivå
        upp till ministernivå.
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde exportera självförsvarsvapen till Taiwan, även om detta skulle medföra försämrade relationer med Folkrepubliken Kina?`,
    desc: (
      <p>
        I nuläget är det bara USA som exporterar självförsvarsvapen till Taiwan,
        inom ramen för "Taiwan Relations Act" som förbinder USA att hjälpa
        Taiwan att bygga upp ett självförsvar för att förhindra eller försvåra
        en kinesisk invasion. Detta gör att Taiwan är ytterst beroende av ett
        enda lands välvilja. Samtidigt är Sverige en vapenexportör som
        specialiserar sig på högteknologiska vapen, bl.a. luftvärnssystem och
        stridsflyg, och även asymmetriska vapen som handburna pansarvärnsvapen,
        som är den sortens vapen som mycket väl skulle kunna vara det som Taiwan
        behöver för att försvara sig. Svensk vapenexport till Taiwan skulle även
        gynna den svenska försvarsindustrin. Eftersom Taiwan inte är en
        konfliktzon finns det (utöver kinesiska invändningar) inga rimliga
        hinder för vapenexport till Taiwan (i synnerhet då Sverige levererar
        vapensystem till Ukraina, som ju redan befinner sig i krig).
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde aktivt stödja Taiwans meningsfulla deltagande på den internationella scenen, bl.a. i WHO och Interpol, även om detta skulle medföra försämrade relationer med Folkrepubliken Kina?`,
    desc: (
      <p>
        Taiwan förhindras att delta i internationella organisationer på grund av
        påtryckningar från Kina, som kräver att få vara det land som
        internationellt representerar Taiwan, trots att kinesiska myndigheter
        inte har några som helst befogenheter i Taiwan. Detta har medfört att
        Taiwan, som länge var ett mönsterland när det gällde kampen mot Covid,
        inte kunnat delta i WHOs arbete. På samma sätt förhindras Taiwan från
        att få information från Interpol om aktuella ärenden, vilket försvårar
        brottsbekämpning både inom och utanför Taiwan. Om fler länder stödde
        Taiwan i dess strävan att delta på ett meningsfullt sätt i
        internationella organisationer, skulle Taiwan kunna bidra till världen
        med sin expertis i olika områden.
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde verka för att snarast införa direktflyg mellan Stockholm och Taipei?`,
    desc: (
      <p>
        Det finns i nuläget inget flygbolag som flyger direkt mellan Taipei och
        Stockholm (eller någon annan nordisk huvudstad). Direkta flygresor
        skulle förenkla kontakten för svenskar som vill resa till Taiwan och
        taiwaneser som vill resa till Sverige. Direktflyg är också betydligt
        bättre för miljön än mellanlandningar som medför att det blir fler
        starter och landningar, som är den delen av flygningen som drar mest
        bränsle och skapar mest CO2. Att starta flyglinjer kräver medverkan av
        aktuella flygbolag, men även av politiken i de berörda länderna, och
        Taiwan har länge varit positivt till direkta flygförbindelser till
        Norden. Det som krävs mest nu är intresse från svensk sida.
      </p>
    ),
  },
  {
    question: `Anser du att Sveriges representanter i olika internationella idrottsförbund börverka för att taiwanesiska atleter internationellt får delta under namnet "Taiwan" i stället för nuvarande "Chinese Taipei"?`,
    desc: (
      <p>
        Just nu får Taiwan inte tävla internationellt under sitt eget namn, utan
        måste använda namnet "Chinese Taipei", på grund av påtryckningar från
        Kina på olika idrottsarrangörer som IOK. För många taiwanesiska atleter
        känns namnet stötande, ungefär som om Österrike skulle tvingas tävla
        under namnet "German Vienna". Syftet är att minimera Taiwans synlighet
        internationellt. Ju fler länder som ifrågasätter detta arrangemang,
        desto större är chansen att detta kan ändras.{" "}
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde medverka till att Taiwan förenas med Folkrepubliken Kina under modellen "Ett land, två system" som tillämpats i Hongkong, även om detta sker mot den taiwanesiska befolkningens vilja?`,
    desc: (
      <p>
        Folkrepubliken Kina har ända sedan 1949 haft som mål att införliva
        Taiwan, som hittills aldrig styrts eller kontrollerats från
        Folkrepubliken Kina. Vi kan titta på ett aktuellt exempel när den förra
        brittiska kolonin Hongkong överlämnades till Kina 1997. Systemet "Ett
        land, två system" skulle garantera att Hongkong fick behålla sin
        demokrati och sitt samhällsskick under 50 år. År 2019 avskaffades
        demokratin i Hongkong fullständigt, och många politiker som tillhör
        eller tillhörde de demokratiska partierna i Hongkong är nu fängslade.
        Den fria pressen finns inte längre. Det är denna modell som
        Folkrepubliken Kina vill tillämpa även på Taiwan. Det är en försvinnande
        liten andel av befolkningen i Taiwan som vill ha en förening med Kina
        under dessa premisser (eller under några som helst premisser).
      </p>
    ),
  },
  {
    question: `Anser du att Skatteverket och andra svenska myndigheter bör ändra tillbaka Taiwans benämning i sina register från "Taiwan, provins i Kina" till "Taiwan"?`,
    desc: (
      <p>
        År 2018 ändrade Skatteverket och vissa andra svenska myndigheter
        benämningen av Taiwan till "Taiwan, provins i Kina", som är den
        benämning som Kina har krävt ska användas internationellt. Utöver att
        detta upplevs som stötande av taiwaneser, medför det även allvarliga
        praktiska problem: ett resultat är bl.a. att svensk myndighetspost till
        svenskar bosatta i Taiwan ofta hamnar felaktigt i Kina och inte
        vidarebefordras till den rätta adressaten.
      </p>
    ),
  },
  {
    question: `Anser du att Sverige borde explicit inkludera demokratin Taiwan i sin "demokratioffensiv" som lanserades 2019?`,
    desc: (
      <p>
        Sverige lanserade 2019 en politik som kallades "demokratioffensiv" med
        syftet att understödja demokratisering världen över, men även att
        förstärka samarbetet med andra demokratier. Taiwan, som räknas som ett
        av de friaste samhällena i Asien enligt olika mätningar, bl.a. Freedom
        House, skulle vara en självklar partner i detta arbete.
      </p>
    ),
  },
  {
    question: `Anser du att svenska städer och kommuner borde etablera partnerstadsrelationer med städer i Taiwan?`,
    desc: (
      <p>
        Viktiga kontakter byggs ofta upp genom partnerstadssamarbeten med
        liknande städer i andra länder. Här kan städerna på båda sidor vinna
        mycket, såväl kulturellt som ekonomiskt, genom att, i vårt fall, svenska
        städer samarbetar med städer i Taiwan som är i en liknande situation.
        Det kan gälla allt från kulturellt utbyte till samarbete inom
        miljöteknik. I ett samarbete med en taiwanesisk stad är man också säker
        på att de myndigheter man interagerar med är demokratiskt valda och
        delar samma demokratiska värderingar som svenska politiker.
      </p>
    ),
  },
  {
    question: `Anser du att Taiwan idag är en naturlig allierad i den aktuella konflikten mellan den liberala och den auktoritära världen?`,
    desc: (
      <p>
        Världen håller på att polariseras i två läger: det liberala lägret, som
        stödjer demokrati, social rättvisa, jämställdhet, yttrandefrihet,
        erkännande av sexuella minoriteters rättigheter och nationellt
        självbestämmande, och det auktoritära lägret som i stället stödjer
        auktoritära ledare, undergräver demokrati och tryckfrihet, motverkar
        sexuella minoriteter och värderar stormakters intresseområden högre än
        nationellt självbestämmande. Det liberala lägret inkluderar Sverige och
        de flesta övriga EU-länder, samt många andra västländer, och även bl.a.
        Taiwan och Japan, medan det auktoritära lägret inkluderar bl.a.
        Ryssland, Kina, Iran och Nordkorea. Det är inte längre möjligt att
        förhålla sig neutral mellan dessa läger. Att försvara demokratin världen
        över kräver att alla demokratiska länder samarbetar, och detta borde
        rimligtvis inkludera Taiwan.
      </p>
    ),
  },
];

export const analysis = [kd, cc, mp, ll, ssd, mm, ssocd, vv];
