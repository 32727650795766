import React, { useState } from "react";

export const Analysis = (props: any) => {
  const { setStage, restart, questions, myAns, parties } = props;
  const [selectParty, setSelectParty] = useState<number>(0);

  const renderIconList = (item: any, i: number) => {
    return (
      <img
        className={selectParty === i ? "active" : ""}
        src={item.img}
        alt={item.name}
        key={i}
        onClick={() => setSelectParty(i)}
      />
    );
  };

  return (
    <div className="analysis-section">
      <div className="analysis-blk">
        <div className="title-row">
          <h3>Analys</h3>
          <button onClick={() => setStage("result")} className="primary small">
            Dina resultat
          </button>
        </div>

        {/* Content */}
        {questions.map((item: any, i: any) => {
          return (
            <div className="question" key={i}>
              <h2>
                {String(i + 1)}/ {item.question}
              </h2>
              <p>Mitt svar: {myAns[i]}</p>
              <div className="icon-list">
                {parties.map((item: any, i: any) => {
                  return renderIconList(item, i);
                })}
              </div>
              {/* Party Answer */}
              <div className="party-ans shadow">
                <p>{parties[selectParty]["answers"][i]["description"]}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bottom-section">
        <div className="restart" onClick={() => restart()}>
          <img src="/assets/refreshing.png" alt="restart-icon" />
          <p>Omstart</p>
        </div>
      </div>
    </div>
  );
};
